import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Image = (props) => {
  return (
    <LazyLoadImage
      {...props}
      alt={props.src}
      effect="blur"
      className={"loading_img"}
    />
  );
};
export default React.memo(Image);
