import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Header from "./Thema/header";
import { string } from "../constants/languages";

function CampaignDetail({
  cafe,
  campaign: { campaign_id, title, short_desc, desc, image } = {},
  color,
  onBack,
}) {
  return (
    <div className="hide-scrollbar" style={{ display: "grid" }}>
      <Header
        id={"campaign_detail"}
        cafe={cafe}
        color={color}
        title={title}
        onBack={onBack}
      />

      <div id={"scroll_campaign_detail"} className="scroll">
        <img width={"100%"} src={image} />
        <div style={{ padding: 20 }}>
          <div style={{ fontSize: 20, color, fontWeight: "bold" }}>{title}</div>
          <div
            style={{ padding: "30px 0 0 0" }}
            dangerouslySetInnerHTML={{ __html: desc }}
          />
        </div>
      </div>
    </div>
  );
}

export default CampaignDetail;
