import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Categories from "./routes/categories";
import "./App.css?v1";
import Home from "./routes/home";
import Product from "./routes/product";
import Basket from "./routes/basket";

export default function App() {
  return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/categories/:cafe_id/:app_id/:theme">
              <Categories />
            </Route>
            <Route path="/categories/:cafe_id/:app_id">
              <Categories />
            </Route>
            <Route path="/product/:cafe_id/:app_id/:product_id">
              <Product />
            </Route>
            <Route path="/basket/:cafe_id/:app_id">
              <Basket />
            </Route>
          </Switch>
        </Router>
      </div>

  );
}
