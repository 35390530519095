import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { languageText } from "../functions/language";
import Header from "./Thema/header";
import { string } from "../constants/languages";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { priceText } from "../functions/priceText";
import { post } from "../networking/Server";

function Search({ cafe, color, onBack, onClick }) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { cafe_id, app_id } = useParams();

  function onSearch(text) {
    if (!text) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    post("qr/search_product", {
      text,
      app_id,
      my_cafe: true,
      cafe_id: window.atob(cafe_id),
    }).then((res) => {
      console.warn(res);
      setLoading(false);
      if (res.result) {
        setData(res.products);
      }
    });
  }

  return (
    <div className="hide-scrollbar">
      <Header
        id={"search"}
        cafe={cafe}
        color={color}
        title={string.urunara}
        onBack={onBack}
      />
      <Input.Search
        placeholder={string.urunismi}
        loading={loading}
        size="large"
        allowClear
        onSearch={() => {
          onSearch(search);
        }}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
      />
      <div id={"search"} className={"scroll"}>
        {data.map(
          ({
            product_id,
            active,
            name,
            description,
            price,
            discount_price,
            images,
          }) => {
            let image = "";

            try {
              image = JSON.parse(images)[0];
            } catch (e) {}

            return (
              <div
                key={product_id}
                onClick={() => {
                  onClick(product_id);
                }}
                style={{
                  cursor: "pointer",
                  ...(!active ? { background: "#f0f0f0" } : {}),
                  borderRadius: 5,
                  borderBottom: "1px solid #f0f0f0",
                  padding: "10px 10px",
                  margin: "15px 25px 15px 25px",
                  flexDirection: "row",
                  display: "flex",
                  gap: 10,
                }}
              >
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {languageText(name)}
                  </span>
                  <p
                    style={{
                      marginTop: 3,
                      lineHeight: 1,
                      fontSize: 12,
                    }}
                    className="description"
                  >
                    {languageText(description)}
                  </p>

                  <div
                    style={{
                      color,
                      fontSize: 16,
                      fontWeight: "bold",
                      display: "flex",
                      gap: 8,
                    }}
                  >
                    <span>
                      {discount_price
                        ? priceText(discount_price, cafe.currency)
                        : priceText(price, cafe.currency)}
                    </span>
                    {discount_price ? (
                      <span
                        style={{
                          fontSize: 12,
                          textDecoration: "line-through",
                        }}
                      >
                        {priceText(price, cafe.currency)}
                      </span>
                    ) : null}
                  </div>
                  {!active ? (
                    <p
                      style={{
                        margin: "6px 0 0 0",
                        color: "#E64A19",
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      {string.mevcutdegil}
                    </p>
                  ) : null}
                </div>
                <div>
                  {image ? (
                    <img
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: 20,
                        objectFit: "cover",
                      }}
                      src={image}
                      alt={image}
                    />
                  ) : null}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

export default Search;
