import { useEffect, useState } from "react";
import {post} from "../networking/Server";
import {error, success} from "../functions/toast";
import {useParams} from "react-router-dom";
import {string} from "../constants/languages";
import {findOrderStatus} from "../functions/orderManager";
import {languageText} from "../functions/language";
import {priceText} from "../functions/priceText";
import {l_moment} from "../functions/l_moment";
import ProductOptions from "../Components/ProductOptions";

let interval = null;


function Orders({cafe,color}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    let { cafe_id, app_id } = useParams();

    useEffect(()=>{
        getOrders()

        if(interval){
            clearInterval(interval)
            interval=0
        }

        interval=setInterval(()=>{
            getOrders()
        },3000)

        return(()=>{
            if(interval){
                clearInterval(interval)
                interval=0
            }
        })
    },[])

    function getOrders() {
        post("qr/api/orders/get", {app_id }).then((res) => {
            setLoading(false)
            if (res.result) {

                setData([...res.orders]);
            } else {
                error()
            }
        });
    }




    if (loading) {
        return (
            <div
                style={{
                    overflow: "scroll",
                    padding: "0 0 100px 0",
                    backgroundColor: "white",
                    flex: 1,
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className="hide-scrollbar" style={{ display: "grid" }}>
            <div id={"scroll_orders"} className="scroll">
                {
                    Array.isArray(data) && data.length?
                        <div>
                            {
                                data.map((item,index)=>{
                                    const {
                                        order_id,
                                        order_type_id,
                                        order_products,
                                        cafe,
                                        price,
                                        createdAt,
                                    } = item;
                                    let status = findOrderStatus(item);

                                    return(
                                        <div
                                            key={index}
                                            onClick={() => {

                                            }}
                                            style={{
                                                borderBottom:"2px solid #f0f0f0",
                                                alignItems:'flex-start',
                                                display:'flex',
                                                flexDirection: "row",
                                                padding: 20,
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div style={{ flex: 2 }}>
                                                <div style={{ paddingLeft: 10 }}>
                                                    {Array.isArray(order_products)
                                                        ? order_products.map(
                                                            ({ product,options, note,count }, index) => {
                                                                return (
                                                                    <div style={{marginBottom:3}}>
                                                                        <h3
                                                                            key={index}
                                                                            style={{
                                                                                margin:0,
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {"*"+(count>1?(count+"X"):"")+languageText(product["name"])}
                                                                        </h3>
                                                                        {note ? (
                                                                            <h4
                                                                                style={{
                                                                                    margin:0,
                                                                                    display:'flex',
                                                                                    fontWeight: "bold",
                                                                                }}
                                                                            >
                                                                                Not:<h4 style={{margin:0, marginLeft:3, color }}>{note}</h4>
                                                                            </h4>
                                                                        ) : null}
                                                                        <ProductOptions
                                                                            show_selected
                                                                            free={false}
                                                                            disabled
                                                                            size={0.7}
                                                                            color={color}
                                                                            data={options}
                                                                            currency={cafe["currency"]}

                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                        : null}
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    display:'flex',
                                                    flex: 1,
                                                    flexDirection:'column',
                                                    justifyContent: "center",
                                                    alignItems: "flex-end",
                                                    borderRadius: 15,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                        color: status["color"],
                                                    }}
                                                >
                                                    {status["value"]}
                                                </div>

                                                {price ? (
                                                    <div
                                                        style={{
                                                            fontSize: 13,
                                                            fontWeight: "bold",
                                                            color,
                                                        }}
                                                    >
                                                        {priceText(price, cafe["currency"])}
                                                    </div>
                                                ) : null}

                                                <div style={{ fontSize: 12, color: 'gray' }}>
                                                    {l_moment(createdAt).fromNow()}
                                                </div>

                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <div>
                            <h3 style={{marginTop:10, width:'100%',textAlign:'center'}}>{string.siparisinizbulunmamakta}</h3>
                        </div>
                }
            </div>
        </div>
    );
}

export default Orders;
