import { Avatar } from "antd";
import { useParams } from "react-router-dom";

function Footer({ cafe }) {
  const { cafe_id, app_id } = useParams();

  let android_id = app_id;

  if (android_id == "com.cyberistanbul.erzurumcagkebabpostane")
    android_id = "com.cyberistanbul.erzurumcagkebab";
  return (
    <footer
      style={{
        minHeight: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 20,
      }}
    >
      {cafe.ios_id ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: 15,
              textAlign: "center",
            }}
          >
            Mobil Uygulamamızdan Sipariş Verebilirsiniz
          </p>
          <a
            href={"https://play.google.com/store/apps/details?id=" + android_id}
            target="_blank"
          >
            <img
              height={"70"}
              src={require("../../assets/images/appstore.png").default}
            />
          </a>

          <a
            href={"https://apps.apple.com/tr/app/" + cafe.ios_id}
            target="_blank"
          >
            <img
              height={"70"}
              src={require("../../assets/images/playstore.png").default}
            />
          </a>
        </div>
      ) : null}

      <div style={{ fontWeight: "bold", fontSize: 18 }}>
        <Avatar
          src={cafe.image}
          title={cafe.name}
          size="large"
          style={{ margin: 10 }}
        />
        {cafe.name}
      </div>
    </footer>
  );
}

export default Footer;
