import { languageText } from "./language";

export function checkOptions(data) {
  try {
    if (!data || !Array.isArray(data) || !data.length) return false;

    for (const { title, options, multiple, require } of data) {
      if (multiple && !require) continue;

      let flag = false;
      for (const { selected } of options) {
        if (selected) flag = true;
      }
      if (!flag) return languageText(title);
    }
  } catch (e) {
    return "Bir Sorun Oluştu";
  }

  return false;
}
