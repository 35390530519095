import Axios from "./Axios";
import {MStore} from "./stores/MainStore";

let token = "";
let task: Promise<import("axios").AxiosResponse<any>>;

export async function post(adres: string, params: any = {}, loading = false) {
    if(MStore.token)
        Axios.defaults.headers.common["x-access-token"] = "Bearer " + MStore.token;


    params = { ...params, web:true };


  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      task = Axios.post(adres, params);
      resolve(
        task
          .then(({ data }) => {
            //IStore.setConnection(0);
            try {
              data = JSON.parse(data);
            } catch {}

            return data;
          })
          .catch((err) => {
            //IStore.setConnection(1);
            return { result: false, error: "No_Connect" };
          })
      );
    }, 1000);
  });
}

export function getTask() {
  return task;
}

export async function cancelPost(task: any) {
  task.cancel((res: any) => {
    console.warn("iptal");
  });
}

export function getURL() {
  return "https://cyberistanbul.com:5000/";
}

export function getImageURL() {
  return getURL();
}
