import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {post} from "../networking/Server";
import {error, success} from "../functions/toast";
import Header from "./Thema/header";
import { string } from "../constants/languages";
import { languageText } from "../functions/language";
import {priceText, calculatePrice, calculateBasket, calculateBasketScore} from "../functions/priceText";
import {
    PlusCircleFilled,
    MinusCircleOutlined,
    DeleteOutlined,
    LoadingOutlined, ArrowLeftOutlined
} from "@ant-design/icons";
import {
    Tabs
} from "antd";
import {MStore} from "../networking/stores/MainStore";
import ProductOptions from "../Components/ProductOptions";
import Tables from "./tables";
import {OrderTypes} from "../constants/Config";
import {createOrder} from "../functions/orderManager";
import Orders from "./orders";
import {controlOrderSystem} from "../functions/controlOrderSystem";

let color = "#31c149";

function Basket() {
    const [loading, setLoading] = useState(true);
    const [loading_add, setLoadingAdd] = useState(false);
    const [loading_item, setLoadingItem] = useState(0);
    const [data, setData] = useState([]);
    const [cafe, setCafe] = useState({});
    const [table_modal, setTableModal] = useState(false);
    const [table, setTable] = useState({});
    const [page, setPage] = useState("1");

    const history = useHistory();
    let { cafe_id, app_id } = useParams();

    const total_price = calculateBasket(data);


    useEffect(()=>{
        if(controlOrderSystem())
            getBasket()
        else
            history.push("/categories/"+cafe_id+"/"+app_id);
    },[])

    function getBasket() {
        post("qr/api/basket/get", {app_id }).then((res) => {
            setLoading(false)
            setLoadingItem(0)
            if (res.result) {
                try {
                    color = JSON.parse(res["cafe"]["colors"]).primary;
                } catch (e) {}
                setData([...res.basket]);
                setCafe({ ...res.cafe });

                setPage((Array.isArray(res.basket) && res.basket.length)?"1":"2")
            } else {
                error()
                history.goBack();
            }
        });
    }

    async function pay(token) {
        const res = await post("qr/api/pay/pay", {token,app_id})

        return !!res.result;
    }

    function createToken() {
        const order = createOrder({
            basket:data,
            total_price,
            table,
            order_type_id: OrderTypes.QRSiparis.id,
        });

        if (!order) {
            error();
            return;
        }

        setLoadingAdd(true)
        post("qr/api/pay/create_token", {
            app_id,
            order,
            cardToken:-1,
            cafe,
            score: 0,
        }).then((res) => {
            if (res.result) {
                pay(res.token).then((res) => {
                    setLoadingAdd(true)
                    if (res) {
                        MStore.setBasketCount(0)
                        setPage("2")
                        setLoadingAdd(false)
                        getBasket()
                        success();
                    } else {
                        error();
                    }
                });
            } else {
                setLoadingAdd(true)
                error();
            }
        });
    }

    function setBasket(data,pos) {
        setLoadingItem(data["basket_id"]);
        post("qr/api/basket/set", {...data,app_id}).then((res) => {
            if (res.result) {
                MStore.setBasketCount(MStore.basket_count+pos)
                getBasket();
            } else {
                setLoadingItem(0)
                error();
            }
        });
    }


    function removeItem(basket_id) {
        setBasket({ basket_id, status: 0 },-1);
    }

    function changeCount(data,count,pos) {
        setBasket({
            ...data,
            count,
            price: calculatePrice(
                data.price,
                data.discount_price,
                count,
                data.options
            ),
        },pos);
    }


    if (loading) {
        return (
            <div
                style={{
                    overflow: "scroll",
                    padding: "0 0 100px 0",
                    backgroundColor: "white",
                    flex: 1,
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }

    return <div className="hide-scrollbar" style={{ display: "grid" }}>

        {
            table_modal?
                <div style={{backgroundColor:'white', zIndex:999, display:'flex',position:'absolute', borderRadius:10,flex:1,top:70,bottom:50,left:20,right:20,boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)"}}>
                    <Tables
                        color={color}
                        cafe={cafe}
                        onSelect={(table)=>{
                            setTable({...table})
                            setTableModal(false)
                        }}
                        onClose={()=>{
                            setTableModal(false)
                        }}
                    />
                </div>:null
        }

        <div
            style={{
                cursor: "pointer",
                padding:20,
                paddingBottom:2
            }}
            onClick={()=>{history.goBack()}}
        >
            <ArrowLeftOutlined
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flex: 1,
                    fontSize: 20,
                    color,
                }}
            />
        </div>

        <Tabs tabBarGutter={100}  size="large" centered activeKey={page} onChange={(page)=>{setPage(page)}}>
            <Tabs.TabPane tab={string.sepet} key="1">
                <div id={"scroll_basket"} className="scroll">
                    {
                        Array.isArray(data) && data.length? data.map(({basket_id, product, count, options, score: product_score, note},index)=>{
                            let { product_id, name, discount_price, score, price } = product;
                            name = languageText(name);
                            return(
                                <div key={index} style={{display:'flex',padding:20,paddingRight:5,borderBottom:"0.1px solid gray", width:'100%',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                    <div style={{display:'flex', flex:2,flexDirection:'column'}}>
                                        <h3 style={{margin:0, fontWeight:'bold'}}>{name}</h3>
                                        <h3 style={{color, fontWeight:'bold'}}>
                                            {priceText(
                                                calculatePrice(
                                                    price,
                                                    discount_price,
                                                    count,
                                                    options
                                                ),
                                                cafe.currency
                                            )}
                                        </h3>
                                        <div
                                            style={{
                                                padding: 3,
                                            }}
                                        >
                                            <ProductOptions
                                                show_selected
                                                free={false}
                                                disabled
                                                size={0.9}
                                                color={color}
                                                data={options}
                                                currency={cafe["currency"]}

                                            />
                                        </div>
                                    </div>
                                    <div style={{display:'flex',flex:1}}>
                                        {
                                            loading_item==basket_id?
                                                <div style={{width:'100%', boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)", display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center', backgroundColor:'white',margin:3,padding:10,borderRadius:10}}>
                                                    <LoadingOutlined style={{color,fontSize:20}}/>

                                                </div>:
                                                <div style={{width:'100%',boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)", display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center', backgroundColor:'white',margin:3,padding:5,borderRadius:10}}>
                                                    <div
                                                        style={{padding:"5px 20px 5px 3px"}}
                                                        onClick={()=>{
                                                            if(count==1){
                                                                removeItem(basket_id)
                                                                return;
                                                            }
                                                            changeCount({
                                                                basket_id,
                                                                options,
                                                                price,
                                                                discount_price
                                                            },count-1,-1)
                                                        }}
                                                    >
                                                        {
                                                            count==1?
                                                                <DeleteOutlined style={{color,fontSize:20}}/>:
                                                                <MinusCircleOutlined style={{color,fontSize:20}}/>

                                                        }
                                                    </div>
                                                    <div style={{fontSize:16,fontWeight:'bold'}}>{count}</div>
                                                    <div
                                                        style={{padding:"5px 3px 5px 20px"}}
                                                        onClick={()=>{
                                                            changeCount({
                                                                basket_id,
                                                                options,
                                                                price,
                                                                discount_price
                                                            },count+1,1)
                                                        }}
                                                    >
                                                        <PlusCircleFilled  style={{color,fontSize:20}}/>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            )
                        }):<div>
                            <h3 style={{marginTop:10, width:'100%',textAlign:'center'}}>{string.sepetinizdeurunbulunmamakta}</h3>
                        </div>
                    }
                    <div style={{ height: "50px" }} />
                </div>
                <div style={{position:'absolute',bottom:10,left:0,right:0, }}>
                    {
                        table.table_id?<h2 onClick={()=>setTableModal(true)} style={{margin:0, width:'100%',textAlign:'center',textDecorationLine:'underline'}}>{table.table_category.name+" "+table.table_num}</h2>:null
                    }
                    {
                        Array.isArray(data) && data.length?
                            <div style={{display:'flex', flexDirection:'row',justifyContent:'space-around',alignItems:'center', backgroundColor:color,margin:15,padding:3,borderRadius:10}}>
                                <div style={{display:'flex',flex:1.5,flexDirection:'column',justifyContent:'space-around',alignItems:'center',margin:3,padding:5,borderRadius:10}}>
                                    <h3 style={{color:'white',fontWeight:'bold',margin:0}}>{priceText(total_price, cafe.currency)}</h3>
                                    <h4 style={{color:'white',margin:0}}>{string.toplam}</h4>
                                </div>
                                {
                                    loading_add?
                                        <div
                                            style={{display:'flex',  flex:1,flexDirection:'column',borderLeft:'1px solid white', justifyContent:'center',alignItems:'center',marginLeft:0,marginRight:4, margin:10,padding:5}}
                                        >
                                            <LoadingOutlined style={{color:'white',fontSize:20,fontWeight:'bold'}}/>
                                        </div>:
                                        <div
                                            onClick={()=>{
                                                if(!table.table_id)
                                                    setTableModal(true)
                                                else
                                                    createToken()
                                            }}
                                            style={{display:'flex',  flex:1,flexDirection:'row',borderLeft:'1px solid white', justifyContent:'center',alignItems:'center',marginLeft:0,marginRight:4, margin:10,padding:5}}
                                        >
                                            <h3 style={{color:'white',fontWeight:'bold',margin:0}}>{"Sipariş Ver"}</h3>
                                        </div>
                                }
                            </div>:
                            <div onClick={()=>{ history.goBack();}} style={{display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:color,margin:15,padding:3,borderRadius:10}}>
                                <h2 style={{margin:20, color:'white',fontWeight:'bold'}}>{string.alisverisedevamet}</h2>
                            </div>
                    }
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Siparişler" key="2">
                <Orders cafe={cafe} color={color}/>
            </Tabs.TabPane>

        </Tabs>


    </div>;
}

export default Basket;
