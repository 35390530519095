import {MStore} from "../networking/stores/MainStore";
import {getSetting} from "../constants/setting";

export function controlOrderSystem() {
  if(MStore.token && getSetting().qr_web_order){
    return true
  }

  return false
}
