export const tr = {
  girdiginizplakayaaitaracbulunamadi:"Girdiğiniz Plakaya Ait Araç Bulunamadı",
  cagir:"Çağır",
  plakanizigiriniz:"Plakanızı Giriniz",
  garsoncagir:"Garson Çağır",
  garsoncagirsuccess:"En kısa süre içerisinde masanıza garson gelecek.",
  valecagir:"Vale Çağır",
  valecagirsuccess:"En kısa süre içerisinde aracınız hazır edilecektir.",
  uzgunuz1:"Üzgünüz...",
  uzgunuz2:"Şu anda bu sayfaya erişim izniniz bulunmamakta.",
  uzgunuz3:"Lütfen işletme yetkilileri ile iletişime geçiniz.",
  ucretsiz:"Ücretsiz",
  urunsepetinizeeklendi:"Ürün Sepetinize Eklendi",
  yenisiparis: "Yeni Sipariş",
  hazirlaniyor: "Hazırlanıyor",
  hazir: "Hazır",
  tamamlandi: "Tamamlandı",
  odendi: "Ödendi",
  iptal: "İptal",
  iade: "İade",
  yolda: "Yolda",
  alisverisedevamet:"Alışverişe Devam Et",
  siparisinizbulunmamakta:"Siparişiniz Bulunmamakta",
  sepetinizdeurunbulunmamakta:"Sepetinizde Ürün Bulunmamakta",
  bulundugunuzalaniseciniz:"Bulunduğunuz Alanı Seçiniz",
  masanumarasiniseciniz:"Masa Numarasını Seçiniz",
  odanumarasiseciniz:"Oda Numarasını Seçiniz",
  sepet:"Sepet",
  eklemekistediginiznot:"Eklemek İstediğiniz Not Var İse;",
  hataolustu: "Bir Sorun Oluştu",
  hataolustuaciklama: "İşlem gerçekleşemedi.",
  basarili: "Başarılı",
  basariliaciklama: "İşlem başarıyla tamamlandı",
  bosolamaz: "Bu Alan Boş Olamaz",
  secimyok: "Seçim Yapmak İstemiyorum",
  sepeteekle:"Sepete Ekle",
  toplam:"Toplam",
  kalori: "Kalori",
  hazirlanmasuresi: "Yaklaşık Hazırlanma Süresi",
  opsiyonlar: "Opsiyonlar",
  sepeteeklemekicinuygulamayiindir: "Sepete Eklemek İçin Uygulamayı İndir",
  bizidegerlendir: "Bizi\nDeğerlendirin",
  mevcutdegil: "Mevcut Değil",
  ara: "Ara",
  urunismi: "Ürün İsmi",
  urunara: "Ürün Ara",
  kampanyayok: "Şu an hiç kampanyamız bulunmamaktadır",
  kampanyalar: "Kampanyalar",
  telefon: "Telefon",
  address: "Adres",
  kapali: "Kapalı",
  pazartesi: "Pazartesi",
  sali: "Salı",
  carsamba: "Çarşamba",
  persembe: "Perşembe",
  cuma: "Cuma",
  cumartesi: "Cumartesi",
  pazar: "Pazar",
  hakkimizda: "Hakkımızda",
  calismasaatleri: "Çalışma Saatleri",
  sosyalmedya: "Sosyal Medya",
  iletisim: "İletişim",
  dil: "tr",
};
