export const en = {
  uzgunuz1:"We are sad...",
  uzgunuz2:"You do not currently have permission to access this page.",
  uzgunuz3:"Please contact the business authorities.",
  ucretsiz:"Free",
  urunsepetinizeeklendi:"Product Added to Your Cart",
  yenisiparis: "New Order",
  hazirlaniyor: "Preparing",
  hazir: "Ready",
  tamamlandi: "Completed",
  odendi: "Paid",
  iptal: "Cancel",
  iade: "Refund",
  yolda: "On the Road",
  alisverisedevamet:"Continue Shopping",
  siparisinizbulunmamakta:"Your order is not found",
  sepetinizdeurunbulunmamakta:"There is no product in your cart",
  bulundugunuzalaniseciniz:"Select Your Area",
  masanumarasiniseciniz:"Choose Table Number",
  odanumarasiseciniz:"Choose Room Number",
  sepet:"Shopping Card",
  eklemekistediginiznot:"If there is a note you want to add;",
  hataolustu: "There is a problem",
  hataolustuaciklama: "The operation failed.",
  basarili: "Successful",
  basariliaciklama: "Transaction completed successfully",
  bosolamaz: "This Field Cannot Be Empty",
  secimyok: "I don't want to choose",
  sepeteekle:"Add Shopping Card",
  toplam:"Total",
  kalori: "Calorie",
  hazirlanmasuresi: "Preparation Time",
  opsiyonlar: "Options",
  sepeteeklemekicinuygulamayiindir: "Download App to Add to Cart",
  bizidegerlendir: "Rate Us",
  mevcutdegil: "Not Available",
  ara: "Search",
  urunismi: "Product Name",
  urunara: "Search Product",
  kampanyayok: "We currently do not have any promotions",
  kampanyalar: "Campaigns",
  telefon: "Phone",
  address: "Address",
  kapali: "Closed",
  pazartesi: "Monday",
  sali: "Tuesday",
  carsamba: "Wednesday",
  persembe: "Thursday",
  cuma: "Friday",
  cumartesi: "Saturday",
  pazar: "Sunday",
  hakkimizda: "About Us",
  calismasaatleri: "Working Hours",
  sosyalmedya: "Social Media",
  iletisim: "Contact",
  dil: "en",
};
