import { Avatar, Divider, Select } from "antd";
import { string } from "../constants/languages";
import moment from "moment";
import Header from "./Thema/header";

const days = [
  string.pazartesi,
  string.sali,
  string.carsamba,
  string.persembe,
  string.cuma,
  string.cumartesi,
  string.pazar,
];

const socials = {
  face: {
    logo: require("../assets/images/socials/facebook.png").default,
    name: "FACEBOOK",
    color: "#3c5997",
  },
  insta: {
    logo: require("../assets/images/socials/instagram.png").default,
    name: "INSTAGRAM",
    color: "#f43287",
  },
  linked: {
    logo: require("../assets/images/socials/linkedin.png").default,
    name: "LINKEDIN",
    color: "#4476b0",
  },
  twitter: {
    logo: require("../assets/images/socials/twitter.png").default,
    name: "TWITTER",
    color: "#55aced",
  },
  whatsapp: {
    logo: require("../assets/images/socials/whatsapp.png").default,
    name: "WHATSAPP",
    color: "#27a71a",
  },
  youtube: {
    logo: require("../assets/images/socials/youtube.png").default,
    name: "YOUTUBE",
    color: "#ea412c",
  },
};

const today_week = moment().isoWeekday();

function CafeDetail({
  cafe: {
    cafe_id,
    image,
    name,
    about,
    opening_hours,
    social_media,
    phone,
    address,
  },
  color,
  isActive,
}) {
  try {
    opening_hours = JSON.parse(opening_hours);
    if (!opening_hours) throw "";
  } catch (e) {
    opening_hours = [];
  }

  if (!cafe_id) return null;

  return (
    <div className="hide-scrollbar" style={{ display: "grid" }}>
      <Header
        id={"cafe"}
        cafe={{
          image,
          name,
          about,
          opening_hours,
          social_media,
          phone,
          address,
        }}
        color={color}
      />
      <div id={"scroll_cafe"} className="scroll">
        {about ? (
          <div>
            <Divider orientation="left" style={{ fontWeight: "bold" }}>
              {string.hakkimizda}
            </Divider>
            <div style={{ padding: "0 30px 10px 30px", fontSize: 14 }}>
              {about}
            </div>
          </div>
        ) : null}

        {Array.isArray(opening_hours) && opening_hours.length ? (
          <div>
            <Divider orientation="left" style={{ fontWeight: "bold" }}>
              {string.calismasaatleri}
            </Divider>
            <div style={{ padding: "0 30px 10px 30px", fontSize: 14 }}>
              {opening_hours.map(({ open, start, end }, day) => {
                return (
                  <div
                    key={day}
                    style={{
                      display: "flex",
                      color: open ? "#2E7D32" : "#C62828",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      ...(today_week == day + 1
                        ? { fontSize: 16, fontWeight: "bold" }
                        : {}),
                    }}
                  >
                    {days[day]}:
                    {open ? (
                      <span style={{ fontSize: 16 }}>
                        {moment(start, "HH:mm:aa").format("HH:mm")} -{" "}
                        {moment(end, "HH:mm:aa").format("HH:mm")}
                      </span>
                    ) : (
                      <span style={{ fontSize: 16 }}>{string.kapali}</span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        {social_media &&
        typeof social_media === "object" &&
        Object.values(social_media).length ? (
          <div>
            <Divider orientation="left" style={{ fontWeight: "bold" }}>
              {string.sosyalmedya}
            </Divider>
            <div className={"grid_2"} style={{ padding: 20 }}>
              {Object.entries(social_media).map(([key, url]) => {
                const { logo, name, color } = socials[key];
                if (!logo) return null;
                if (key == "whatsapp") {
                  url = `https://wa.me/90${url}?text=Merhaba`;
                }

                return (
                  <a
                    key={key}
                    target={"_blank"}
                    href={url}
                    style={{
                      margin: 10,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img width={40} src={logo} />
                    <div
                      style={{
                        padding: 10,
                        fontSize: 14,
                        color: color,
                        fontWeight: "bold",
                      }}
                    >
                      {name}
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        ) : null}

        {address || phone ? (
          <div>
            <Divider orientation="left" style={{ fontWeight: "bold" }}>
              {string.iletisim}
            </Divider>
            <div style={{ padding: "0 30px 10px 30px" }}>
              {phone ? (
                <div style={{ padding: 5, fontSize: 14, fontWeight: "bold" }}>
                  {string.telefon}: <a href={"tel:" + phone}>{phone}</a>
                </div>
              ) : null}
              {address ? (
                <div
                  style={{
                    padding: 5,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {string.address}:{" "}
                  <a href={"https://maps.google.com/?q=" + address}>
                    {address}
                  </a>
                </div>
              ) : null}
            </div>

            {address && isActive ? (
              <div style={{ padding: "40px 0 0 0" }}>
                <iframe
                  src={`https://maps.google.com/maps?q=${encodeURI(
                    address
                  )}&output=embed`}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  allowFullScreen
                  style={{ width: "100%", height: 350 }}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CafeDetail;
