import ControlObject, {
  OrderAddressesPattern,
  OrderCameTakePattern,
  OrderFollowsPattern,
  OrderPattern,
  OrderProductsPattern,
  OrderQRsPattern,
  OrderTablesPattern,
} from "../constants/Patterns";
import { calculatePrice } from "./priceText";
import { toParse } from "./json";
import { OrderStatuses } from "../constants/Config";
// @ts-ignore
import { v1 as uuid } from "uuid";
import {MStore} from "../networking/stores/MainStore";

export function shortTableName(string) {
  let new_string = "";

  try {
    string = string.replace(/\s/g, " ");
    string.split(" ").map((value, index, array) => {
      new_string += value.charAt(0);
    });
  } catch (e) {}

  return new_string.toUpperCase();
}

export function createOrder({
  basket,
  total_price,
  table,
  address,
  date,
  order_type_id,
}) {
  let order_qrs = [];
  let order_products = [];
  let order_tables = [];
  let order_cametake = [];
  let order_addresses = [];
  let customer = MStore.user;

  if (table) {
    order_tables = [
      {
        ...OrderTablesPattern,
        table_id: table.table_id,
        table: {
          table_num: table.table_num,
          table_category: {
            name: table.table_category["name"],
          },
        },
      },
    ];

    order_qrs = [
      {
        ...OrderQRsPattern,
        table_id: table.table_id,
      },
    ];
  }

  if (address) {
    order_addresses = [
      {
        ...OrderAddressesPattern,
        customer_address_id: address.customer_address_id,
        customer_address: {
          title: address.title,
          city: address.city,
          district: address.district,
          address: address.address,
          description: address.description,
          customer,
        },
      },
    ];
  }

  if (date) {
    order_cametake = [
      {
        ...OrderCameTakePattern,
        date,
      },
    ];
  }

  basket.map(({ product, count, options = "[]", note }) => {
    if (!product["description"]) product["description"] = "";
    if (!product["time"]) product["time"] = "";
    if (!product["calorie"]) product["calorie"] = "";

    const { price, discount_price } = product;

    if (!product["options"]) product["options"] = "[]";
    if (!options) options = "[]";

    order_products.unshift({
      ...OrderProductsPattern,
      price: calculatePrice(price, discount_price, count, toParse(options)),
      product_price: price,
      product_discount_price: discount_price,
      count,
      options: options,
      product_id: product["product_id"],
      product,
      note,
    });
  });

  const order = {
    ...OrderPattern,
    uuid: uuid(),
    order_type_id,
    price: total_price,
    orderAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
    order_tables,
    order_products,
    order_qrs,
    order_addresses,
    order_cametake,
    order_follows: [
      {
        ...OrderFollowsPattern,
        order_status_id: OrderStatuses.YeniSiparis.id,
        createdAt: new Date(),
      },
    ],
  };

  if (ControlObject(order)) {
    return order;
  } else {
    return false;
  }
}

export function findOrderStatus(order) {
  let status = {};
  try {
    let { order_follows } = order;
    order_follows = order_follows[0];
    status = Object.values(OrderStatuses).filter(
      (e) => e.id == order_follows["order_status_id"]
    )[0];
    status = { ...status, createdAt: order_follows["createdAt"] };
  } catch (e) {}

  return status;
}
