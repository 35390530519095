import { useEffect, useState } from "react";
import { Avatar, Select } from "antd";
import _ from "lodash";
import { post } from "../../networking/Server";
import { useHistory, useParams } from "react-router-dom";
import {
  DownCircleTwoTone,
  DownOutlined,
  GiftOutlined,
  HomeOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { priceText } from "../../functions/priceText";
import { languageText, menuLanguages } from "../../functions/language";
import $ from "jquery";
import CafeDetail from "../cafe_detail";
import Campaigns from "../campaigns";
import Footer from "./footer";
import Header from "./header";
import { string } from "../../constants/languages";
import Image from "../../Components/Image";

function Thema1({
  cafe,
  products,
  color,
  onClick = () => {},
  onSearch = () => {},
}) {
  const [selectedCatIndex, setSelectedCatIndex] = useState(0);
  const [selectedSubCatIndex, setSelectedSubCatIndex] = useState(0);
  const [positions, setPositions] = useState([]);
  const [cats, setCats] = useState([]);

  const { cafe_id, app_id } = useParams();
  const history = useHistory();

  let listener = null;
  let old_data = 0;

  function createData(data) {
    try {
      for (const cat of data) {
        if (cat.products.length) {
          cat.subcategory.unshift({ ...cat });
        }
      }
    } catch (e) {}
    return data;
  }

  const initialPositions = (cats, index) => {
    if (cats.length && typeof index === "number") {
      const header = document.getElementById("theme_1"),
        headerHeight = header.offsetHeight;

      const { subcategory = [] } = cats[index];

      const _positions = new Array(subcategory.length).fill(0);

      for (const [i, cat] of subcategory.entries()) {
        const header = document.getElementById(`header-${cat.category_id}`);

        _positions[i] = header.offsetTop;
      }

      setPositions(_positions);
    }
  };

  useEffect(() => {
    setCats(createData(cafe?.categories));
  }, []);

  useEffect(() => {
    if (!cats.length) return;
    const products = document.getElementById("scroll_theme_1");
    products.removeEventListener("scroll", listener);

    initialPositions(cats, selectedCatIndex);
  }, [cats, selectedCatIndex]);

  useEffect(() => {
    if (positions.length) {
      const products = document.getElementById("scroll_theme_1");

      listener = () => {
        try {
          let winScroll = products.scrollTop;

          const closest = positions.reduce(function (prev, curr) {
            return Math.abs(curr - winScroll) < Math.abs(prev - winScroll)
              ? curr
              : prev;
          });

          const index = _.indexOf(positions, closest);

          if (index != -1) {
            if (index != old_data) {
              old_data = index;

              setSelectedSubCatIndex(index);
              const { category_id, name } =
                cats[selectedCatIndex].subcategory[index];

              document
                .getElementById("btn-" + category_id)
                .scrollIntoView({ block: "center" });
            }
          }
        } catch {}
      };

      products.addEventListener("scroll", listener);
    }
  }, [positions]);

  if (!Array.isArray(cats) || !cats.length) {
    return null;
  }

  return (
    <div className="hide-scrollbar">
      <Header onSearch={onSearch} id={"theme_1"} cafe={cafe} color={color}>
        <div
          id="top-categories"
          className="hide-scrollbar"
          style={{
            backgroundColor: color,
            display: "flex",
            overflow: "auto",
            flexDirection: "row",
            padding: "10px 20px 10px 20px",
          }}
        >
          {cats.map(({ category_id, name }, i) => {
            return (
              <div
                id={"top-btn-" + category_id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedCatIndex(i);
                  document
                    .getElementById("scroll_theme_1")
                    .scrollTo({ top: 0 });
                  setSelectedSubCatIndex(0);

                  var pos = $("#top-btn-" + category_id).position().left; //get left position of li
                  var currentscroll = $("#top-categories").scrollLeft(); // get current scroll position
                  var divwidth = $("#top-categories").width(); //get div width
                  pos = pos + currentscroll - divwidth / 2;

                  document
                    .getElementById("top-categories")
                    .scrollTo({ left: pos + 20, behavior: "smooth" });
                }}
              >
                <div
                  style={
                    i == selectedCatIndex
                      ? {
                          whiteSpace: "nowrap",
                          flex: 1,
                          backgroundColor: "white",
                          borderRadius: 8,
                          padding: "8px 20px 8px 20px",
                          color,
                          fontWeight: "bold",
                          fontSize: 14,
                        }
                      : {
                          whiteSpace: "nowrap",
                          flex: 1,
                          padding: "8px 20px 8px 20px",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: 14,
                        }
                  }
                >
                  {languageText(name)}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="hide-scrollbar"
          id="buttons"
          style={{
            boxShadow:
              "0px 10px 13px -15px #000000, 0px 13px 21px 1px rgba(0,0,0,0)",
            backgroundColor: "white",
            display: "flex",
            overflow: "auto",
            flexDirection: "row",
            padding: "10px 20px 10px 20px",
          }}
        >
          {cats[selectedCatIndex]?.subcategory.map(
            ({ name, category_id }, i) => {
              return (
                <div
                  id={"btn-" + category_id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const el = document.getElementById("header-" + category_id);

                    el.scrollIntoView();
                  }}
                >
                  <div
                    style={
                      i == selectedSubCatIndex
                        ? {
                            whiteSpace: "nowrap",
                            backgroundColor: color,
                            borderRadius: 8,
                            padding: "6px 18px 6px 18px",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 12,
                          }
                        : {
                            whiteSpace: "nowrap",
                            padding: "6px 18px 6px 18px",
                            color,
                            fontWeight: "bold",
                            fontSize: 12,
                          }
                    }
                  >
                    {languageText(name)}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </Header>

      <div id="scroll_theme_1" className="scroll">
        {cats[selectedCatIndex].subcategory.map(
          ({ name, products, category_id }, i) => {
            return (
              <div className="product-header" id={"header-" + category_id}>
                <div
                  style={{
                    padding: "15px 20px 15px 20px",
                    fontWeight: "bold",
                    fontSize: 14,
                    background: "#f0f0f0",
                  }}
                >
                  {languageText(name)}
                </div>
                <div>
                  {products.map(
                    (
                      {
                        name,
                        images,
                        price,
                        discount_price,
                        product_id,
                        description,
                        active,
                      },
                      i
                    ) => {
                      let image = "";
                      try {
                        image = JSON.parse(images)[0];
                      } catch {}
                      return (
                        <div
                          //key={product_id}
                          onClick={() => {
                            onClick(product_id);

                            /*history.push(
                              "/product/" +
                                cafe_id +
                                "/" +
                                app_id +
                                "/" +
                                product_id
                            );*/
                          }}
                          style={{
                            cursor: "pointer",
                            ...(!active ? { background: "#f0f0f0" } : {}),
                            borderRadius: 5,
                            borderBottom: "1px solid #f0f0f0",
                            padding: "10px 10px",
                            margin: "15px 25px 15px 25px",
                            flexDirection: "row",
                            display: "flex",
                            gap: 10,
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                              }}
                            >
                              {languageText(name)}
                            </span>
                            <span
                              style={{
                                display: "flex",
                                marginTop: 3,
                                lineHeight: 1,
                                fontSize: 12,
                                whiteSpace: "break-spaces",
                              }}
                              className="description"
                            >
                              {languageText(description)}
                            </span>

                            <div
                              style={{
                                color,
                                fontSize: 16,
                                fontWeight: "bold",
                                display: "flex",
                                gap: 8,
                              }}
                            >
                              <span>
                                {discount_price
                                  ? priceText(discount_price, cafe.currency)
                                  : priceText(price, cafe.currency)}
                              </span>
                              {discount_price ? (
                                <span
                                  style={{
                                    fontSize: 12,
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {priceText(price, cafe.currency)}
                                </span>
                              ) : null}
                            </div>
                            {!active ? (
                              <p
                                style={{
                                  margin: "6px 0 0 0",
                                  color: "#E64A19",
                                  fontWeight: "bold",
                                  fontSize: 14,
                                }}
                              >
                                {string.mevcutdegil}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            {image ? (
                              <Image
                                key={product_id}
                                style={{
                                  width: 80,
                                  height: 80,
                                  borderRadius: 20,
                                  objectFit: "cover",
                                }}
                                src={image}
                                alt={image}
                              />
                            ) : null}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            );
          }
        )}
        <Footer cafe={cafe} />
      </div>
    </div>
  );
}

export default Thema1;
