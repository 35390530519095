import {Avatar, Badge, Select} from "antd";
import { useParams,useHistory } from "react-router-dom";
import { useEffect } from "react";
import $ from "jquery";
import { menuLanguages } from "../../functions/language";
import { ArrowLeftOutlined, SearchOutlined ,ShoppingOutlined,ShoppingFilled} from "@ant-design/icons";
import {
  getLanguage,
  setLanguage,
} from "../../constants/languages";
import { getSetting } from "../../constants/setting";
import {Observer} from "mobx-react-lite";
import {MStore} from "../../networking/stores/MainStore";
import {controlOrderSystem} from "../../functions/controlOrderSystem";

function Header({ id, cafe, color, children, title, onBack, onSearch,lock }) {
  const { cafe_id, app_id } = useParams();
  const history = useHistory();

  let listener = null;
  let remove_name = false;
  let remove_language = false;
  let image_width = 1;
  let qr_image_border_radius = 1000;

  try {
    const setting = getSetting();
    remove_name = setting["qr_remove_name"];
    remove_language = setting["qr_remove_language"];
    image_width = setting["qr_image_width"];
    qr_image_border_radius = setting["qr_image_border_radius"]??1000;
  } catch (e) {}

  useEffect(() => {
    initialCss();
    let old_pos = true;

    const products = document.getElementById("scroll_" + id);
    if (products) {
      listener = () => {
        try {
          const pos = products.scrollTop;

          if (pos < 50) {
            if (!old_pos) {
              changeHeader(true);
              old_pos = true;
            }
          } else {
            if (old_pos) {
              console.warn(pos, old_pos);
              changeHeader(false);
              old_pos = false;
            }
          }
        } catch {}
      };

      products.addEventListener("scroll", listener);
    }
  }, []);

  function changeHeader(original = true) {
    const _image_height = original ? 140 : remove_name ? 40 : 30;
    const _image_width = original
      ? 140 * image_width
      : image_width == 1
      ? (remove_name ? 40 : 30) * image_width
      : 100 * image_width;
    const fontSize = original ? 18 : 10;

    $("#logo_" + id).css({ height: _image_height, width: _image_width });
    $("#cafe_name_" + id).css({ fontSize });
    //initialCss();
  }
  const initialCss = () => {
    try {
      const headerHeight = document.getElementById(id).offsetHeight;

      /*document.getElementById(
        "scroll_" + id
      ).style.height = `calc(100vh - ${headerHeight}px)`;*/
    } catch (e) {}
  };

  const language = getLanguage();

  if(lock){
    return (
        <header id={id}>
          <div
              style={{
                backgroundColor: color,
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                zIndex: 20,
                padding: 10,
                boxShadow:
                    "0px 8px 13px -15px #000000, 0px 13px 21px 1px rgba(0,0,0,0)",
              }}
          >

            {title ? (
                <div>
                  <div
                      className={"animation-font"}
                      style={{
                        textAlign: "center",
                        fontSize: 18,
                        padding: "0 50px 0 50px",
                        fontWeight: "bold",
                      }}
                  >
                    {title}
                  </div>
                </div>
            ) : (
                <div>
                  <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                  >
                    <Avatar
                        id={"logo_" + id}
                        className={"animation-size"}
                        src={cafe?.image}
                        title={""}
                        style={{
                          padding: "3%",
                          //backgroundColor: "#ffffff70",
                          width: 140 * image_width,
                          height: 140,
                          margin: 5,
                          borderRadius:qr_image_border_radius
                        }}
                    />
                    <div
                        className={"animation-font"}
                        style={{
                          textAlign: "center",
                          fontSize: 24,
                          fontWeight: "bold",
                        }}
                        id={"cafe_name_" + id}
                    >
                      {!remove_name ? cafe?.name : null}
                    </div>
                  </div>
                  <div style={{padding:20, display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center', position: "absolute", left: 0, top: 0 }}>
                    {!remove_language ? (
                        <Select

                            suffixIcon={null}
                            onSelect={(language) => {
                              setLanguage(language);
                            }}
                            defaultValue={language}
                            style={{ padding: 0,margin:0 }}
                            bordered={false}
                            size="large"
                            onChange={() => {}}
                        >
                          {menuLanguages.map((e, index) => (
                              <Select.Option
                                  key={index}
                                  style={{ padding: 0 }}
                                  value={e.lang}
                              >
                                <Avatar size="small" src={e.icon} title={e.name} />
                              </Select.Option>
                          ))}
                        </Select>
                    ) : null}
                  </div>


                </div>
            )}
          </div>

        </header>
    );
  }

  return (
    <header id={id}>
      <div
        style={{
          backgroundColor: color,
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          zIndex: 20,
          padding: 10,
          boxShadow:
            "0px 8px 13px -15px #000000, 0px 13px 21px 1px rgba(0,0,0,0)",
        }}
      >
        {onBack ? (
          <div
            style={{
              zIndex: 9999,
              position: "absolute",
              left: 0,
              padding: 20,
              cursor: "pointer",
            }}
            onClick={onBack}
          >
            <ArrowLeftOutlined
              style={{
                display: "flex",
                alignItems: "flex-start",
                flex: 1,
                fontSize: 20,
                color: "white",
              }}
            />
          </div>
        ) : null}

        {title ? (
          <div>
            <div
              className={"animation-font"}
              style={{
                textAlign: "center",
                fontSize: 18,
                padding: "0 50px 0 50px",
                fontWeight: "bold",
              }}
            >
              {title}
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                id={"logo_" + id}
                className={"animation-size"}
                src={cafe?.image}
                title={""}
                style={{
                  padding: "3%",
                  //backgroundColor: "#ffffff70",
                  width: 140 * image_width,
                  height: 140,
                  margin: 5,
                  borderRadius:qr_image_border_radius
                }}
              />
              <div
                className={"animation-font"}
                style={{
                  textAlign: "center",
                  fontSize: 24,
                  fontWeight: "bold",
                }}
                id={"cafe_name_" + id}
              >
                {!remove_name ? cafe?.name : null}
              </div>
            </div>
            {onSearch ? (
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center', position: "absolute", left: 0, top: 0 }}>

                <div
                    style={{paddingLeft:15,paddingBottom:10, paddingRight:5,padding: 20}}
                    onClick={() => {
                      onSearch();
                    }}
                >
                  <SearchOutlined
                      style={{ fontSize: 20, color: "white" }}
                  />
                </div>
                {!remove_language ? (
                    <Select
                        suffixIcon={null}
                        onSelect={(language) => {
                          setLanguage(language);
                        }}
                        defaultValue={language}
                        style={{ padding: 0,margin:0 }}
                        bordered={false}
                        size="large"
                        onChange={() => {}}
                    >
                      {menuLanguages.map((e, index) => (
                          <Select.Option
                              key={index}
                              style={{ padding: 0 }}
                              value={e.lang}
                          >
                            <Avatar size="small" src={e.icon} title={e.name} />
                          </Select.Option>
                      ))}
                    </Select>
                ) : null}
              </div>
            ) : null}

            <div style={{ position: "absolute", right: 0, top: 5 }}>
              <Observer
                  render={()=>{
                    if(!controlOrderSystem())
                      return null
                    return(
                        <div
                            style={{paddingRight:25,padding: 20}}
                            onClick={() => {
                              history.push('/basket/'+cafe_id+"/"+app_id);
                            }}
                        >
                          <Badge showZero={false} count={MStore.basket_count}>
                            <ShoppingFilled
                                style={{ fontSize: 27, color: "white"}}
                            />
                          </Badge>
                        </div>

                    )
                  }}
              />

            </div>
          </div>
        )}
      </div>
      {children ? children : null}
    </header>
  );
}

export default Header;
