import {string} from "../constants/languages";

export function priceText(
    price,
    currency = "",
    fixed = 2
) {
  if (!price || price=="0") return string.ucretsiz;
  return parseFloat(price).toFixed(fixed).toString() + "" + currency;
}

export function calculatePrice(
    price,
    price2,
    count = 1,
    options = []
) {
  if (price2) price = price2;

  try {
    options.map(({ options }) => {
      for (const { selected, price: o_price } of options) {
        if (selected) price += o_price;
      }
    });
  } catch (e) {
    console.warn(e);
  }
  price = price * count;
  return price;
}
export function calculateBasket(basket) {
  let total = 0;

  basket.map(
      ({ count, product: { price, discount_price }, options, score }) => {
        if (score) {
          return;
        }

        total += calculatePrice(price, discount_price, count, options);
      }
  );

  return total;
}
export function calculateBasketScore(basket) {
  let total = 0;

  basket.map(({ count, product: { score } }) => {
    if (score) total += score * count;
  });

  return total;
}
