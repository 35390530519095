import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {post} from "../networking/Server";
import {error} from "../functions/toast";
import {string} from "../constants/languages";
import {
    LeftOutlined,
    RightOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";

function Tables({color,cafe,onSelect=()=>{},onClose=()=>{}}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [open_table, setOpenTable] = useState(0);

    const cafe_type_id = cafe?.cafe_type_id ?? 1;


    let { cafe_id, app_id } = useParams();
    cafe_id = parseInt(window.atob(cafe_id))

    useEffect(()=>{
        getTables()
    },[])

    function getTables() {
        setLoading(true)
        post("qr/api/tables/get_all", { app_id, cafe_id }).then(
            (res) => {

                setLoading(false);
                if (res.result) {
                    setData(res.tables);
                } else {
                    error()
                }
            }
        );
    }



    function findTableName(table_category_id) {
        let name = "";

        try {
            name = data.find((e) => e.table_category_id == table_category_id)[
                "name"
                ];
            if (!name) {
                throw "";
            }
        } catch (e) {
            name = "";
        }

        return name;
    }

    function findTables(table_category_id) {
        let c_tables = [];

        try {
            c_tables = data.find(
                (e) => e.table_category_id == table_category_id
            )["tables"];
            if (!Array.isArray(c_tables)) {
                throw "";
            }
        } catch (e) {
            c_tables = [];
        }

        return c_tables;
    }

    function findTableCategory(table_category_id) {
        let category = {};

        try {
            category = data.find(
                (e) => e.table_category_id == table_category_id
            );
            if (!category["table_category_id"]) {
                throw "";
            }
        } catch (e) {
            category = {};
        }

        return category;
    }


    if (loading) {
        return (
            <div
                style={{
                    overflow: "scroll",
                    padding: "20px 20px 100px 20px",
                    backgroundColor: "white",
                    flex: 1,

                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={{overflow: "scroll",width:'100%', }}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                {
                    page==1?
                        <LeftOutlined style={{fontSize:24,color,padding:20,paddingBottom:4}} onClick={()=>{setPage(0); setOpenTable(0)}} />:<div/>
                }
                <CloseCircleOutlined style={{fontSize:24,padding:20,paddingBottom:4}} onClick={()=>{onClose()}} />
            </div>
            {
                page==0?
                    <div style={{width:'100%'}}>
                        <div style={{ width: "100%", display: "flex",justifyContent:'center', padding:20}}>
                            <h2 style={{fontWeight:'bold',textAlign:'center'}}>{string.bulundugunuzalaniseciniz}</h2>
                        </div>
                        {Array.isArray(data)
                            ? data.map(
                                (
                                    { table_category_id, name, tables: c_tables },
                                    i
                                ) => {
                                    return (
                                        <div style={{padding:"1px 20px"}} key={i}>
                                            <div
                                                onClick={() => {
                                                    try {
                                                        setOpenTable(table_category_id);
                                                        setPage(1)
                                                    } catch (e) {}
                                                }}
                                                style={{
                                                    display:'flex',
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <h1
                                                    style={{fontWeight: "bold", color,}}
                                                >
                                                    {name}
                                                </h1>
                                                <RightOutlined style={{fontSize:20,color}} />
                                            </div>
                                        </div>
                                    );
                                }
                            )
                            : null}
                        <div style={{ height: "50px" }} />
                    </div>:
                    page==1?
                        <div style={{width:'100%'}}>
                            <div style={{ width: "100%", display: "flex",flexDirection:'column',justifyContent:'center', padding:20}}>
                                <h1 style={{fontWeight:'bold',textAlign:'center'}}>{findTableName(open_table)}</h1>
                                <h2 style={{fontWeight:'bold',textAlign:'center'}}>{cafe_type_id == 2 ? string.odanumarasiseciniz : string.masanumarasiniseciniz}</h2>
                            </div>
                            <div
                                style={{
                                    display:'flex',
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                }}
                            >
                                {findTables(open_table).map((table, i) => {
                                    return (
                                        <div
                                            style={{
                                                margin:10,
                                                display:'flex',
                                                height: 50,
                                                width: 50,
                                                border:"0.5px solid gray",
                                                borderRadius:10,
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            key={i}
                                        >
                                            <div
                                                onClick={() => {

                                                    onSelect(
                                                        {
                                                            ...table,
                                                            table_category: findTableCategory(open_table),
                                                        }
                                                    )
                                                    setPage(0)
                                                    setOpenTable(0);
                                                }}
                                                style={{ padding: 20}}
                                            >
                                                <h2
                                                    style={{
                                                        fontWeight: "bold",
                                                        color,
                                                        margin:0
                                                    }}
                                                >
                                                    {table.table_num}
                                                </h2>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div style={{ height: "50px" }} />
                        </div>:null
            }

        </div>
    );
}

export default Tables;
