import { string } from "../constants/languages";
import Header from "./Thema/header";

function Campaigns({ cafe, color, onClick = () => {} }) {
  const { campaigns } = cafe;
  return (
    <div className="hide-scrollbar" style={{ display: "grid" }}>
      <Header
        id={"campaigns"}
        cafe={cafe}
        color={color}
        title={string.kampanyalar}
      />

      <div id={"scroll_campaigns"} className="scroll">
        {Array.isArray(campaigns) && campaigns.length ? (
          campaigns.map(({ campaign_id, title, short_desc, desc, image }) => {
            return (
              <div
                key={campaign_id}
                style={{
                  margin: "20px 20px 50px 20px",
                  backgroundColor: "#50505020",
                  borderRadius: 10,
                }}
                onClick={() => {
                  onClick({
                    campaign_id,
                    title,
                    short_desc,
                    desc,
                    image,
                  });
                }}
              >
                <div style={{ padding: 10 }}>
                  <div style={{ fontSize: 20, color, fontWeight: "bold" }}>
                    {title}
                  </div>
                  <div style={{ fontSize: 14 }}>{" " + short_desc?short_desc:""}</div>
                </div>
                <img width={"100%"} src={image} />
              </div>
            );
          })
        ) : (
          <div
            style={{
              padding: 10,
              textAlign: "center",
              fontSize: 16,
              color,
              fontWeight: "bold",
            }}
          >
            {string.kampanyayok}
          </div>
        )}
      </div>
    </div>
  );
}

export default Campaigns;
