import { useEffect, useState } from "react";
import _ from "lodash";
import { post } from "../networking/Server";
import { useHistory, useParams } from "react-router-dom";
import { GiftOutlined, HomeOutlined, QrcodeOutlined } from "@ant-design/icons";
import CafeDetail from "./cafe_detail";
import Campaigns from "./campaigns";
import Product from "./product";
import Thema1 from "./Thema/thema1";
import Thema2 from "./Thema/thema2";
import Thema3 from "./Thema/thema3";
import CampaignDetail from "./campaign_detail";
import Search from "./search";
import Header from "./Thema/header";
import { setLanguage, string } from "../constants/languages";
import { getSetting, setSetting } from "../constants/setting";
import { MStore } from "../networking/stores/MainStore";
import Tables from "./tables";
import { error, success } from "../functions/toast";
import SelectPlate from "./select_plate";

let color = "#31c149";

const pages = {
  HOME: "Ana Sayfa",
  PRODUCT: "Ürün",
  CAFE: "Kafe Detay",
  CAMPAIGNS: "Kampanyalar",
  CAMPAIGN_DETAIL: "Kampanya Detay",
  SEARCH: "Ürün Ara",
};

let old_page;

function Categories() {
  const [products, setProducts] = useState([]);

  const [cafe, setCafe] = useState({ currency: "" });
  const [page, setPage] = useState({ name: pages.HOME, params: {} });
  const [table_modal, setTableModal] = useState(false);
  const [plate_modal, setPlateModal] = useState(false);
  const [hideIcon, setHideIcon] = useState(false);
  const { cafe_id, app_id, theme } = useParams();
  const history = useHistory();

  /*setTimeout(() => {
    setActive(true);
  }, 15000);*/

  const getCats = async () => {
    post("qr/get_products", { app_id }).then((res) => {
      if (res.result) {
        try {
          setProducts(res.products);

          let cafe = _.find(res.products, {
            cafe_id: parseInt(window.atob(cafe_id)),
          });

          if (cafe) {
            try {
              color = JSON.parse(cafe.colors).primary;
            } catch (e) {}

            try {
              setSetting(cafe["settings"][0]);
              onLogin();
            } catch (e) {}

            setCafe(cafe);

            setTimeout(() => {
              setHideIcon(true);
            }, 3000);

            return;
          }
        } catch {}

        history.push("/");
      }
    });
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    getCats();
  }, []);

  const callWaiter = async (table, plate) => {
    post("qr/add-notification", { app_id, table, plate }).then((res) => {
      if (res.result) {
        if (table) {
          success(string.garsoncagirsuccess);
        } else {
          success(string.valecagirsuccess);
        }
      } else {
        error();
      }
    });
  };

  function onLogin() {
    if (!getSetting().qr_web_order) return;

    try {
      if (localStorage) {
        let another_cafe = MStore.cafe_id != parseInt(window.atob(cafe_id));
        if (another_cafe) {
          MStore.setToken("");
          MStore.setUser({});
          MStore.setBasketCount(0);
        }

        if (!MStore.token || another_cafe) {
          console.warn("logined");
          post("qr/login-anonim", { app_id }).then((res) => {
            if (res.result) {
              MStore.setCafeID(parseInt(window.atob(cafe_id)));
              MStore.setToken(res.token);
              MStore.setUser(res.user);
              MStore.setBasketCount(0);
            }
          });
        }
      }
    } catch (e) {
      window.location.href =
        "https://qrmenu.cyberistanbul.com" + window.location.pathname;
    }
  }

  if (cafe_id == "OTU=" && !window.location.hash) {
    setLanguage("de-DE");
    return <></>;
  }

  if (!cafe || !cafe["cafe_id"]) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  let setting = {};
  try {
    setting = cafe["settings"][0];
  } catch (e) {}

  function getPage({ name, params = {} }) {
    const qr_theme = theme ? theme : setting["qr_theme"];

    return (
      <div>
        {qr_theme == 2 ? (
          <Thema2
            {...params}
            cafe={cafe}
            products={products}
            color={color}
            onClick={(product_id) => {
              setPage({ name: pages.PRODUCT, params: { product_id } });
            }}
            onSearch={() => {
              setPage({ name: pages.SEARCH });
            }}
            setting={setting}
          />
        ) : qr_theme == 3 ? (
          <Thema3
            {...params}
            cafe={cafe}
            products={products}
            color={color}
            onClick={(product_id) => {
              setPage({ name: pages.PRODUCT, params: { product_id } });
            }}
            onSearch={() => {
              setPage({ name: pages.SEARCH });
            }}
          />
        ) : (
          <Thema1
            {...params}
            cafe={cafe}
            products={products}
            color={color}
            onClick={(product_id) => {
              setPage({ name: pages.PRODUCT, params: { product_id } });
            }}
            onSearch={() => {
              setPage({ name: pages.SEARCH });
            }}
          />
        )}

        <div className={`navigate_page ${name == pages.PRODUCT ? "open" : ""}`}>
          <Product
            {...params}
            onBack={() => {
              if (old_page) {
                old_page = null;
                setPage({ name: pages.SEARCH });
              } else {
                setPage({ name: pages.HOME });
              }
            }}
          />
        </div>
        <div className={`navigate_page ${name == pages.CAFE ? "open" : ""}`}>
          <CafeDetail
            {...params}
            isActive={name == pages.CAFE}
            cafe={cafe}
            color={color}
          />
        </div>
        <div
          className={`navigate_page ${name == pages.CAMPAIGNS ? "open" : ""}`}
        >
          <Campaigns
            {...params}
            cafe={cafe}
            color={color}
            onClick={(campaign) => {
              setPage({ name: pages.CAMPAIGN_DETAIL, params: { campaign } });
            }}
          />
        </div>
        <div
          className={`navigate_page ${
            name == pages.CAMPAIGN_DETAIL ? "open" : ""
          }`}
        >
          <CampaignDetail
            {...params}
            color={color}
            cafe={cafe}
            onBack={() => {
              setPage({ name: pages.CAMPAIGNS });
            }}
          />
        </div>

        <div className={`navigate_page ${name == pages.SEARCH ? "open" : ""}`}>
          <Search
            {...params}
            color={color}
            cafe={cafe}
            onBack={() => {
              setPage({ name: pages.HOME });
            }}
            onClick={(product_id) => {
              old_page = { ...page };
              setPage({ name: pages.PRODUCT, params: { product_id } });
            }}
          />
        </div>
      </div>
    );
  }

  const qr_survey = setting["qr_survey"] ?? false;
  let call_waiter = false;
  let call_valet = false;

  try {
    const staff_types = JSON.parse(cafe.user.staff_types);
    if (staff_types.includes(2)) {
      call_waiter = true;
    }
    if (staff_types.includes(8)) {
      call_valet = true;
    }
  } catch (e) {}

  if (!cafe.user.purchase)
    return (
      <div className="hide-scrollbar">
        <Header lock id={"no-auth"} cafe={cafe} color={color} />
        <div
          style={{
            padding: 50,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: 22,
              fontWeight: "bold",
              color: color,
              textAlign: "center",
            }}
          >
            {string.uzgunuz1}
          </div>
          <div style={{ fontSize: 18, textAlign: "center" }}>
            {string.uzgunuz2}
          </div>
          <div style={{ fontSize: 18, textAlign: "center" }}>
            {string.uzgunuz3}
          </div>
        </div>
      </div>
    );

  return (
    <div className="hide-scrollbar">
      {getPage(page)}

      {table_modal ? (
        <div
          style={{
            backgroundColor: "white",
            zIndex: 999,
            display: "flex",
            position: "absolute",
            borderRadius: 10,
            flex: 1,
            top: 70,
            bottom: 50,
            left: 20,
            right: 20,
            boxShadow:
              "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <Tables
            color={color}
            cafe={cafe}
            onSelect={(table) => {
              callWaiter(table, null);
              setTableModal(false);
            }}
            onClose={() => {
              setTableModal(false);
            }}
          />
        </div>
      ) : null}
      {plate_modal ? (
        <div
          style={{
            backgroundColor: "white",
            zIndex: 999,
            display: "flex",
            position: "absolute",
            borderRadius: 10,
            flex: 1,
            top: 70,
            bottom: 50,
            left: 20,
            right: 20,
            boxShadow:
              "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <SelectPlate
            color={color}
            onSelect={(plate) => {
              callWaiter(null, plate);
              setPlateModal(false);
            }}
            onClose={() => {
              setPlateModal(false);
            }}
          />
        </div>
      ) : null}

      <style>
        {`
                        .right-icon-hide span{
                            visibility: hidden !important;
                           
                        }
                        
                        .right-icon-hide{
                             right: -90px !important;
                        }
                        
                        .right-icon-hide:hover{
                             right: 0 !important;
                        }
                        
                        .right-icon-hide:hover span{
                            visibility: visible !important;
                        }
                        
                    
                    `}
      </style>

      {qr_survey ? (
        <a
          href={"https://qranket.cyberistanbul.com/surveys/" + cafe_id}
          style={{
            position: "absolute",
            right: 0,
            bottom: "30%",
            zIndex: 9999999,
            maxWidth: 145,
            minWidth: 145,
            transition: "300ms all",
          }}
          className={hideIcon ? "right-icon-hide" : ""}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "1em",
              alignItems: "center",
              fontSize: 10,
              fontWeight: "bold",
              color: "white",
              padding: 10,
              borderBottomLeftRadius: 40,
              borderTopLeftRadius: 40,
              backgroundColor: "#e53935",
            }}
          >
            <img
              style={{ padding: 5 }}
              width={35}
              src={require("../assets/images/feedback.png").default}
            />
            <span
              style={{
                whiteSpace: "break-spaces",
                display: "block",
                textAlign: "center",
                transition: "300ms all",
              }}
            >
              {string.bizidegerlendir}
            </span>
          </div>
        </a>
      ) : null}
      {call_waiter ? (
        <a
          onClick={() => {
            setTableModal(true);
          }}
          style={{
            position: "absolute",
            right: 0,
            bottom: "50%",
            zIndex: 9999999,
            maxWidth: 145,
            minWidth: 145,
            transition: "300ms all",
          }}
          className={hideIcon ? "right-icon-hide" : ""}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "1em",
              alignItems: "center",
              fontSize: 10,
              fontWeight: "bold",
              color: "white",
              padding: 10,
              borderBottomLeftRadius: 40,
              borderTopLeftRadius: 40,
              backgroundColor: "#FFA000",
            }}
          >
            <img
              style={{ padding: 5 }}
              width={35}
              src={require("../assets/images/waiter.png").default}
            />
            <span
              style={{
                whiteSpace: "break-spaces",
                display: "block",
                textAlign: "center",
                transition: "300ms all",
              }}
            >
              {string.garsoncagir}
            </span>
          </div>
        </a>
      ) : null}
      {call_valet ? (
        <a
          onClick={() => {
            setPlateModal(true);
          }}
          style={{
            position: "absolute",
            right: 0,
            bottom: "40%",
            zIndex: 9999999,
            maxWidth: 145,
            minWidth: 145,
            transition: "300ms all",
          }}
          className={hideIcon ? "right-icon-hide" : ""}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "1em",
              alignItems: "center",
              fontSize: 10,
              fontWeight: "bold",
              color: "white",
              padding: 10,
              borderBottomLeftRadius: 40,
              borderTopLeftRadius: 40,
              backgroundColor: "#E64A19",
            }}
          >
            <img
              style={{ padding: 5 }}
              width={35}
              src={require("../assets/images/keys.png").default}
            />
            <span
              style={{
                whiteSpace: "break-spaces",
                display: "block",
                textAlign: "center",
                transition: "300ms all",
              }}
            >
              {string.valecagir}
            </span>
          </div>
        </a>
      ) : null}

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 999999,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            flex: 1,
            borderRadius: "0 50px 50px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: page.name == pages.CAFE ? color : "white",
            padding: 15,
          }}
          onClick={() => {
            setPage({ name: pages.CAFE });
          }}
          className={"animation-bg"}
        >
          <HomeOutlined
            className={"animation-font"}
            style={{
              fontSize: page.name == pages.CAFE ? 25 : 20,
              color: page.name == pages.CAFE ? "white" : color,
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            borderRadius: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              page.name == pages.HOME ||
              page.name == pages.PRODUCT ||
              page.name == pages.SEARCH
                ? color
                : "white",
            padding: 15,
          }}
          onClick={() => {
            setPage({ name: pages.HOME });
          }}
          className={"animation-bg"}
        >
          <QrcodeOutlined
            className={"animation-font"}
            style={{
              fontSize:
                page.name == pages.HOME ||
                page.name == pages.PRODUCT ||
                page.name == pages.SEARCH
                  ? 25
                  : 20,
              color:
                page.name == pages.HOME ||
                page.name == pages.PRODUCT ||
                page.name == pages.SEARCH
                  ? "white"
                  : color,
            }}
          />
        </div>

        <div
          style={{
            flex: 1,
            borderRadius: "50px 0 0 50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              page.name == pages.CAMPAIGNS || page.name == pages.CAMPAIGN_DETAIL
                ? color
                : "white",
            padding: 15,
          }}
          onClick={() => {
            setPage({ name: pages.CAMPAIGNS });
          }}
          className={"animation-bg"}
        >
          <GiftOutlined
            className={"animation-font"}
            style={{
              fontSize:
                page.name == pages.CAMPAIGNS ||
                page.name == pages.CAMPAIGN_DETAIL
                  ? 25
                  : 20,
              color:
                page.name == pages.CAMPAIGNS ||
                page.name == pages.CAMPAIGN_DETAIL
                  ? "white"
                  : color,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Categories;

/*
{cafe?.qr_thema == 1 ? (
        <Thema1
          cafe={cafe}
          products={products}
          color={color}
          onClick={(product_id) => {
            setProductID(product_id);
          }}
        />
      ) : (
        <Thema2
          cafe={cafe}
          products={products}
          color={color}
          onClick={(product_id) => {
            setProductID(product_id);
          }}
        />
      )}

      {product_id ? (
        <div
          style={{
            zIndex: 99999,
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "white",
          }}
        >
          <Product
            product_id={product_id}
            onBack={() => {
              setProductID(0);
            }}
          />
        </div>
      ) : null}

      <div className={`navigate_page ${page == 1 ? "open" : ""}`}>
        <CafeDetail cafe={cafe} color={color} />
      </div>
      <div className={`navigate_page ${page == 2 ? "open" : ""}`}>
        <Campaigns cafe={cafe} color={color} />
      </div>

* */
