import {getLanguage } from "../constants/languages";
export const menuLanguages = [
  {
    lang: "en-TR",
    name: "Türkçe",
    icon: require("../assets/images/turkey.png").default,
  },
  {
    lang: "en-US",
    name: "İngilizce",
    icon: require("../assets/images/united-kingdom.png").default,
  },
  {
    lang: "de-DE",
    name: "Almanca",
    icon: require("../assets/images/germany.png").default,
  },
]



export function languageText(text, lang = "") {
  if (!lang) {
    try {
      lang = getLanguage();
    } catch (e) {}
  }

  if (!lang) {
    lang = menuLanguages[0].lang;
  }

  try {
    let res = "";
    if (typeof text == "string") {
      try {
        res = text
            .toString()
            .match(new RegExp(`<${lang}>(.*?)<\\/${lang}>`, "gsm"))[0]
            .replace(`<${lang}>`, "")
            .replace(`</${lang}>`, "");
      } catch (e) {}

      if (!res || res == "undefined") {
        res = text;

        for (const { lang } of menuLanguages) {
          let res = "";

          try {
            res = text
                .toString()
                .match(new RegExp(`<${lang}>(.*?)<\\/${lang}>`, "gms"))[0]
                .replace(`<${lang}>`, "")
                .replace(`</${lang}>`, "");
          } catch (e) {}

          if (res && res != "undefined") return res;
        }
      }
    } else {
      res = text[lang];

      if (!res || res == "undefined") {
        res = text;

        for (const { lang } of menuLanguages) {
          let res = text[lang];
          if (res && res != "undefined") return res;
        }
      }
    }

    return res;
  } catch (e) {}
  try {
    if (!text.includes("</")) return text;
  } catch (e) {}
  return "";
}
