import { observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class MainStoreC {
  @persist @observable token = "";
  @persist("object") @observable user = {};
  @persist @observable cafe_id = 0;
  @persist @observable basket_count = 0;


  @action setToken(data) {
    this.token = data;
  }
  @action setUser(data) {
    this.user = data;
  }
  @action setCafeID(data) {
    this.cafe_id = data;
  }
  @action setBasketCount(data) {
    this.basket_count = data;
  }

}


export const MStore = new MainStoreC();

try {
  const hydrate = create({});
  hydrate("MStore", MStore)
      .then(() => console.info("MStore hydrated"))
      .catch((e) => console.error("Store Error: ", e));
}catch (e) {

}

