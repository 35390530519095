import {string} from "../constants/languages";
import { notification} from 'antd';


export  function success(title = string.basarili,desc = string.basariliaciklama) {
    if(!title){
        title=string.basarili
    }
    if(!desc){
        desc=string.basariliaciklama
    }

    notification.success({
        message: title,
        description: desc,
    });
}

export  function error(title = string.hataolustu,desc = string.hataolustuaciklama) {
    if(!title){
        title=string.hataolustu
    }

    if(!desc && desc!=""){
        desc=string.hataolustuaciklama
    }


    notification.error({
        style:{ zIndex:99999},
        message: title,
        description: desc,
    });
}

