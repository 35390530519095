//@ts-nocheck
import { string } from "./languages";

export const StaffTypes = {
  Kasiyer: { id: 1, value: string.kasiyer },
  Garson: { id: 2, value: string.garson },
  Mutfak: { id: 3, value: string.mutfak },
  Menu: { id: 4, value: string.menu },
  LuksMenu: { id: 5, value: string.luksmenu },
  Market: { id: 6, value: string.market },
  Kurye: { id: 7, value: string.kurye },
};
export const OrderStatuses = {
  YeniSiparis: { id: 1, value: string.yenisiparis, color: "#FBC02D" },
  Hazirlaniyor: { id: 2, value: string.hazirlaniyor, color: "#F57C00" },
  Hazir: { id: 3, value: string.hazir, color: "#009688" },
  Tamamlandi: { id: 4, value: string.tamamlandi, color: "#1E88E5" },
  Odendi: { id: 5, value: string.odendi, color: "#4CAF50" },
  Iptal: { id: 6, value: string.iptal, color: "#E64A19" },
  Iade: { id: 7, value: string.iade, color: "#E64A19" },
  Yolda: { id: 8, value: string.yolda, color: "#4CAF50" },
};
export const OrderTypes = {
  Siparis: { id: 1, value: "" },
  MasaSiparis: { id: 2, value: "" },
  QRSiparis: {
    id: 3,
    value: string.qrsiparis,
    color: "#2196F3",
    desc: string.qrsiparisdesc,
  },
  GelAl: {
    id: 4,
    value: string.gelalservis,
    color: "#43A047",
    desc: string.gelalservisdesc,
  },
  Rezervasyon: {
    id: 5,
    value: string.rezervasyon,
    color: "#E53935",
    desc: "Restoran/Kafe İçin İstediğiniz Zamana Masa Ayırabilirsiniz.",
  },
  YemekliRezervasyon: {
    id: 6,
    value: string.yemeklirezervasyon,
    color: "#FB8C00",
    desc: "Restoran/Kafe İçin İstediğiniz Zamana Masa Ayırabilirsiniz. Üstelik Rezervasyon Yaparken Siparişinizi Verebilirsiniz.",
  },
  AdreseSiparis: {
    id: 7,
    value: string.adresesiparis,
    color: "#673AB7",
    desc: string.adresesiparisdesc,
  },
};

export const PaymentTypes = {
  Nakit: { id: 1, value: string.nakit },
  KrediKarti: { id: 2, value: string.kredikarti },
  Indirim: { id: 3, value: string.indirim },
  ParaUstu: { id: 4, value: string.paraustu },
  IadeIptal: { id: 5, value: string.iadeiptal },
};

export function getOrderType(id: number, key: string = false) {
  let res:any;
  try {
    res = Object.values(OrderTypes).filter((e) => e.id == id)[0];
    if (key) res=res[key];
  } catch (e) {}
  return res;
}
