import { Avatar } from "antd";
import { languageText } from "../../functions/language";
import { toParse } from "../../functions/json";
import { useEffect, useState } from "react";
import $ from "jquery";
import Footer from "./footer";
import Header from "./header";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { priceText } from "../../functions/priceText";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { string } from "../../constants/languages";
import Image from "../../Components/Image";
import { trackWindowScroll } from "react-lazy-load-image-component";

function Thema2({ cafe, products, color, onClick, onSearch }) {
  const [stack, setStack] = useState([
    { category: cafe?.categories, products: [] },
  ]);

  const innerWidth = window.innerWidth;

  function getImage({ products, subcategory }) {
    let image = "";
    try {
      for (const { images } of products) {
        try {
          image = toParse(images)[0];
        } catch (e) {}

        if (image) {
          return image;
        }
      }
    } catch (e) {}

    try {
      for (const { images } of products) {
        try {
          image = toParse(images)[0];
        } catch (e) {}

        if (image) {
          return image;
        }
      }
    } catch (e) {
      for (const { products } of subcategory) {
        for (const { images } of products) {
          try {
            image = toParse(images)[0];
          } catch (e) {}

          if (image) {
            return image;
          }
        }
      }
    }

    return image ? image : require("../../assets/images/dish.png").default;
  }

  function Category(category_id, subcategory, products, name, image) {
    return (
      <div
        key={category_id}
        style={{
          width: "100%",
          borderRadius: 10,
        }}
        onClick={() => {
          stack.push({
            products: Array.isArray(products) ? products : [],
            category: Array.isArray(subcategory) ? subcategory : [],
          });
          setStack([...stack]);

          document.getElementById("scroll_theme_2").scrollTo({ top: 0 });
        }}
      >
        <Image
          key={category_id}
          width={"100%"}
          height={(innerWidth - 50) / 2}
          src={image}
          style={{
            objectFit: "cover",
            minWidth: (innerWidth - 50) / 2,
            minHeight: (innerWidth - 50) / 2,
          }}
        />
        <div
          style={{
            width: "100%",
            backgroundColor: color,
            fontWeight: "bold",
            fontSize: 16,
            color: "white",
            textAlign: "center",
            padding: 7,
          }}
        >
          {languageText(name)}
        </div>
      </div>
    );
  }

  function Product({
    product_id,
    name,
    images,
    active,
    description,
    price,
    discount_price,
  }) {
    let image = require("../../assets/images/dish.png").default;
    try {
      image = JSON.parse(images)[0];
      if (!image) throw "";
    } catch (e) {
      image = require("../../assets/images/dish.png").default;
    }

    description = languageText(description);

    if (description.length > 50)
      description = description.substring(0, 50) + "...";

    return (
      <div
        key={product_id + 1000}
        style={{
          width: "100%",
          borderRadius: 10,
        }}
        onClick={() => {
          onClick(product_id);
        }}
      >
        <Image
          key={product_id + 1000}
          width={"100%"}
          height={(innerWidth - 50) / 2}
          src={image}
          style={{
            objectFit: "cover",
            minWidth: (innerWidth - 50) / 2,
            minHeight: (innerWidth - 50) / 2,
          }}
        />

        <div
          style={{
            width: "100%",
            backgroundColor: color,
            fontWeight: "bold",
            fontSize: 16,
            color: "white",
            textAlign: "center",
            padding: 7,
          }}
        >
          {languageText(name)}
          <div style={{ fontSize: 13, fontWeight: "normal", lineHeight: 1 }}>
            {languageText(description)}
          </div>
          <div style={{ fontSize: 18, padding: "5px 0 10px 0" }}>
            {priceText(discount_price ? discount_price : price, cafe.currency)}
          </div>
          {!active ? (
            <p
              style={{
                margin: "6px 0 0 0",
                color: "white",
                fontWeight: "bold",
                fontSize: 17,
              }}
            >
              {string.mevcutdegil}
            </p>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="hide-scrollbar" style={{ overflow: "none" }}>
      <Header onSearch={onSearch} id={"theme_2"} cafe={cafe} color={color} />
      {stack.length > 1 ? (
        <div
          style={{
            zIndex: 9999,
            backgroundColor: color,
            display: "inline-block",
            borderRadius: 100,
            position: "absolute",
            bottom: 70,
            right: 10,
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
          }}
        >
          <ArrowLeftOutlined
            onClick={() => {
              stack.pop();
              setStack([...stack]);

              document.getElementById("scroll_theme_2").scrollTo({ top: 0 });
            }}
            style={{
              padding: 20,
              cursor: "pointer",
              display: "flex",
              alignSelf: "flex-start",
              flex: 1,
              fontSize: 20,
              color: "white",
            }}
          />
        </div>
      ) : null}
      <div
        id={"scroll_theme_2"}
        className="scroll"
        style={{ padding: "0 0 100px 0" }}
      >
        <Masonry columnsCount={2} gutter={"5"}>
          {Array.isArray(stack) && stack.length > 1
            ? stack[stack.length - 1]["products"].map(
                (
                  {
                    product_id,
                    name,
                    images,
                    active,
                    description,
                    price,
                    discount_price,
                  },
                  index
                ) => {
                  if (Array.isArray(products) && products.length) {
                    return Product({
                      product_id,
                      name,
                      images,
                      active,
                      description,
                      price,
                      discount_price,
                    });
                  } else {
                    return null;
                  }
                }
              )
            : null}

          {Array.isArray(stack) && stack.length
            ? stack[stack.length - 1]["category"].map(
                ({ category_id, image, name, products, subcategory }, index) => {
                  if (!category_id) return null;
                  return Category(
                    category_id,
                    subcategory,
                    products,
                    name,
                      image?image:getImage({ products, subcategory })
                  );
                }
              )
            : null}
        </Masonry>
        <Footer cafe={cafe} />
      </div>
    </div>
  );
}

export default Thema2;
