export const de = {
  uzgunuz1:"Wir sind traurig...",
  uzgunuz2:"Sie sind derzeit nicht berechtigt, auf diese Seite zuzugreifen.",
  uzgunuz3:"Bitte wenden Sie sich an die Gewerbeaufsicht.",
  ucretsiz:"Frei",
  urunsepetinizeeklendi:"Produkt wurde Ihrem Einkaufswagen hinzugefügt",
  yenisiparis: "Neue Ordnung",
  hazirlaniyor: "Vorbereitung",
  hazir: "Fertig",
  tamamlandi: "Abgeschlossen",
  odendi: "Bezahlt",
  iptal: "Abbrechen",
  iade: "Rückerstattung",
  yolda: "Unterwegs",
  alisverisedevamet:"Mit dem Einkaufen fortfahren",
  siparisinizbulunmamakta:"Ihre Bestellung wurde nicht gefunden",
  sepetinizdeurunbulunmamakta:"Es befindet sich kein Produkt in Ihrem Warenkorb",
  bulundugunuzalaniseciniz:"Wählen Sie Ihren Bereich aus",
  masanumarasiniseciniz:"Wählen Sie Tischnummer",
  odanumarasiseciniz:"Wählen Sie Zimmernummer",
  sepet:"Einkaufskarte",
  eklemekistediginiznot:"Wenn Sie eine Notiz hinzufügen möchten;",
  hataolustu: "Es gibt ein Problem",
  hataolustuaciklama: "Die Operation ist fehlgeschlagen.",
  basarili: "Erfolgreich",
  basariliaciklama: "Transaktion erfolgreich abgeschlossen",
  bosolamaz: "Dieses Feld kann nicht leer sein",
  secimyok: "Ich will nicht wählen",
  sepeteekle:"Einkaufskarte hinzufügen",
  toplam:"Gesamt",
  kalori: "Kalorie",
  hazirlanmasuresi: "Ungefähre Vorbereitungszeit",
  opsiyonlar: "Optionen",
  sepeteeklemekicinuygulamayiindir: "Laden Sie die App herunter, um sie in den Warenkorb zu legen",
  bizidegerlendir: "Bewerten\nSie uns",
  mevcutdegil: "Nicht verfügbar",
  ara: "Suchen",
  urunismi: "Produktname",
  urunara: "Produkt suchen",
  kampanyayok: "Wir haben derzeit keine Aktionen",
  kampanyalar: "Wir haben derzeit keine Aktionen",
  telefon: "Telefon",
  address: "Die Anschrift",
  kapali: "Geschlossen",
  pazartesi: "Montag",
  sali: "Dienstag",
  carsamba: "Mittwoch",
  persembe: "Donnerstag",
  cuma: "Freitag",
  cumartesi: "Samstag",
  pazar: "Markt",
  hakkimizda: "über uns",
  calismasaatleri: "Arbeitszeit",
  sosyalmedya: "Sozialen Medien",
  iletisim: "Kommunikation",
  dil: "de",
};
