import React, {useEffect, useState } from "react";

import { priceText } from "../functions/priceText";
import { languageText } from "../functions/language";
import { string } from "../constants/languages";
import {
  DownOutlined
} from "@ant-design/icons";
import { Checkbox,Select } from 'antd';

export default function ProductOptions({
                                         free = false,
                                         style = {},
                                         data,
                                         color = "black",
                                         disabled = false,
                                         size = 1,
                                         onPress,
                                         currency = "",
                                         justShow = false,
                                           show_selected=false
                                       }) {

  useEffect(() => {
  }, []);

  function _onPress(data_index, index, multi = false) {
    try {
      let price = data[data_index]["options"][index]["price"];
      if (free) {
        if (price) {
          /*error(
            "Bu Opsiyon İçin Değişiklik Yapamazsınız",
            "Ücretsiz sipariş vereceğiniz ürünler için ücreli opsiyonlar üzerinde değişiklik yapamazsınız."
          );*/
          return;
        }
      }

      if (!multi)
        data[data_index]["options"] = data[data_index]["options"].map(
            (e) => ({...e, selected: false})
        );

      data[data_index]["options"][index]["selected"] =
          !data[data_index]["options"][index]["selected"];

      onPress(data);
    } catch (e) {
    }
  }



  if (justShow || show_selected) {
    return (
        <div style={style}>
          {Array.isArray(data)
              ? data.map(({title, options, multiple}, index) => {
                title = languageText(title);

                let selecteds = options
                    .filter((e) => show_selected? !!e.selected : true)
                    .map((e) => {
                      let name = languageText(e.name);
                      let length = 20;
                      if (name == "no-choose") name = string.secimyok;

                      return (
                          languageText(name) +
                          (e.price ? " (" + priceText(e.price, currency) + ")" : "")
                      );
                    })
                    .join(", ");

                return (
                    <div style={{padding: 5 * size}}>
                      <div
                          style={{
                            fontSize: 15 * size,
                            fontWeight: "bold",
                            color: selecteds ? color : "black",
                          }}
                      >
                        {title}:{"\n"}
                        <div style={{color: "black"}}>{selecteds}</div>
                      </div>
                    </div>
                );
              })
              : null}
        </div>
    );
  }

  return (
      <div style={style}>

        {Array.isArray(data)
            ? data.map(({title, options, multiple}, index) => {
              title = languageText(title);

              let selecteds = options
                  .filter((e) => !!e.selected)
                  .map((e) => {
                    let name = languageText(e.name);
                    let length = 20;
                    if (name == "no-choose") name = string.secimyok;

                    name =
                        name.length >= length
                            ? name.substring(0, length) + ".."
                            : name;

                    return (
                        languageText(name) +
                        (e.price ? " (" + priceText(e.price, currency) + ")" : "")
                    );
                  })
                  .join(", ");

              if (multiple) {
                return (
                    <div key={index} style={{marginTop: 15 * size}}>
                      <div style={{padding: 7 * size}}>
                        <div
                            style={{
                              fontSize: 15 * size,
                              fontWeight: "bold",
                              color: selecteds ? color : "black",
                            }}
                        >
                          {title}
                        </div>
                      </div>
                      <div
                          style={{
                            zIndex: 1000,
                            /*flexDirection:'row',flexWrap: 'wrap'*/
                          }}
                      >
                        {options.map(
                            (
                                {name, price, selected},
                                option_index
                            ) => (
                                <div key={option_index}  style={{padding: 3*size, backgroundColor: "transparent", borderWidth: 0,}}>
                                  <Checkbox
                                      disabled={disabled}
                                      checked={selected}
                                      onChange={() => {
                                        _onPress(index, option_index, true);
                                      }}
                                  >
                                    <div style={{ fontSize: 15*size }}>
                                      {
                                          languageText(name) +
                                          (price
                                              ? " (" + priceText(price, currency) + ")"
                                              : "")
                                      }
                                    </div>
                                  </Checkbox>
                                </div>
                            )
                        )}
                      </div>
                    </div>
                );
              } else {
                return (
                    <div
                        key={index}
                        style={{
                          marginTop: 10*size,
                            marginLeft:-5
                        }}

                    >

                        <Select
                            defaultValue={null}
                            style={{ margin:0,padding:0, width: '100%' }}
                            bordered={false}
                            size="large"
                            dropdownStyle={{zIndex:999999}}
                            onSelect={(option_index)=>{
                                if (!disabled) _onPress(index, option_index);
                            }}
                            placeholder={<div
                                style={{
                                    fontSize: 15 * size,
                                    fontWeight: "bold",
                                    color: selecteds ? color : "black",
                                }}
                            >
                                {title}
                            </div>}
                        >

                            {
                                Array.isArray(options)
                                    ? options.map(({ name, price },index) =>
                                        <Select.Option key={index} value={index}>{name=="no-choose"?string.secimyok:languageText(name) + (price ? " (" + priceText(price, currency) + ")" : "")}</Select.Option>
                                    )
                                    : []
                            }
                        </Select>

                    </div>
                );
              }
            })
            : null}
      </div>
  );
}
