import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {post} from "../networking/Server";
import {error} from "../functions/toast";
import {string} from "../constants/languages";
import {
    LeftOutlined,
    RightOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import {Button, Input} from "antd";

function SelectPlate({color,onSelect=()=>{},onClose=()=>{}}) {
    const [plate, setPlate] = useState("");
    const [loading, setLoading] = useState(false);

    let {app_id } = useParams();

    function controlPlate(plate) {
        setLoading(true)
        post("qr/control-plate", { app_id, plate }).then(
            (res) => {

                setLoading(false);
                if (res.result) {
                    if(res.plate){
                        onSelect(res.plate)
                    }else{
                        error(string.girdiginizplakayaaitaracbulunamadi)
                    }

                } else {
                    error()
                }
            }
        );
    }






    return (
        <div style={{overflow: "scroll",width:'100%', }}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                <CloseCircleOutlined style={{fontSize:24,padding:20,paddingBottom:4}} onClick={()=>{onClose()}} />
            </div>
            <div style={{width:'100%'}}>
                <div style={{ width: "100%", display: "flex",justifyContent:'center', padding:20}}>
                    <h2 style={{fontWeight:'bold',textAlign:'center'}}>{string.plakanizigiriniz}</h2>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center', padding:50}}>
                    <Input placeholder={"Plaka"} onChange={(e)=>setPlate(e.target.value)}/>
                    <Button
                        loading={loading}
                        primary
                        onClick={()=>{
                            controlPlate(plate)
                        }}
                    >
                        {string.cagir}
                    </Button>
                </div>
            </div>

        </div>
    );
}

export default SelectPlate;
