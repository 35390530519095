export default function ControlObject(
  data: any,
  outof: any = [
    "barcode",
    "user_id",
    "customer_id",
    "createdAt",
    "updatedAt",
    "image",
    "images",
  ]
) {
  const _data: any = [];
  function find(data: any) {
    if (Array.isArray(data)) {
      for (const item of data) {
        find(item);
      }
    } else if (data instanceof Object) {
      for (const key in data) {
        if (!outof.includes(key)) {
          if (!data[key] && data[key] != 0) {
            console.warn("Boş Değer", key, data[key]);
          }
          find(data[key]);
        }
      }
    } else {
      _data.push(data);
    }
  }

  find(data);

  const _newData = _data.filter((e: any) => {
    if (e == 0) return true;
    if (!e) return false;
    return true;
  });

  return _newData.length === _data.length;
}
export const OrderTablesPattern = {
  table_id: null,
  status: 1,
  table: {
    table_num: null,
    table_category: {
      name: null,
    },
  },
};

export const OrderAddressesPattern = {
  customer_address_id: null,
  status: 1,
  customer_address: {
    title: null,
    city: null,
    district: null,
    address: null,
    description: "",
    customer: {
      customer_id: null,
      name: null,
      surname: null,
      phone: "",
      email: null,
    },
  },
};

export const OrderCameTakePattern = {
  status: 1,
  date: null,
};

export const OrderPattern = {
  price: 0,
  score: 0,
  uuid: null,
  order_type_id: null,
  orderAt: null,
  createdAt: null,
  updatedAt: null,
  status: 1,
  order_products: [],
  order_tables: [],
  order_qrs: [],
  order_follows: [],
  order_addresses: [],
};

export const OrderProductsPattern = {
  price: 0,
  product_price: 0,
  product_discount_price: 0,
  count: 1,
  options: [],
  note: "",
  product_id: null,
  status: 1,
  product: {
    product_id: null,
    name: null,
    price: 0,
    discount_price: 0,
    description: "",
  },
};
export const OrderQRsPattern = {
  table_id: null,
  status: 1,
};

export const OrderFollowsPattern = {
  order_status_id: null,
  status: 1,
  createdAt: null,
};
