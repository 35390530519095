import { tr } from "./tr";
import { en } from "./en";
import { de } from "./de";

import moment from "moment";
import "moment/locale/tr";
import { menuLanguages } from "../../functions/language";

export const languages = {
  TR: {
    id: 1,
    title: "Türkçe",
    short: "TR",
    code: "tr",
    icon: "",
    file: tr,
  },
  EN: {
    id: 2,
    title: "English",
    short: "EN",
    code: "en",
    icon: "",
    file: en,
  },
  DE: {
    id: 3,
    title: "German",
    short: "DE",
    code: "de",
    icon: "",
    file: de,
  },
};

export function setLanguage(language: string) {
  window.location.hash = language;
  window.location.reload();
}

export function getLanguage() {
  const local = window.location.hash ?? menuLanguages[0].lang;

  if (equal(local, "tr")) {
    return menuLanguages[0].lang;
  } else if (equal(local, "de")) {
    return menuLanguages[2].lang;
  } else {
    return menuLanguages[1].lang;
  }
}

export function findLanguage() {
  const local = window.location.hash ?? menuLanguages[0].lang;

  if (equal(local, "tr")) {
    return languages.TR;
  } else if (equal(local, "de")) {
    return languages.DE;
  }else {
    return languages.EN;
  }
}

function equal(lang1: string, lang2: string) {
  try {
    if (lang1.split("-")[1].toLowerCase() == lang2) {
      return true;
    } else {
      if (lang1.toLowerCase() == lang2) {
        return true;
      }
      return false;
    }
  } catch {}

  return true;
}

export const algoritm: any = function () {
  const lang = findLanguage();
  moment.locale(lang.code);
  return lang.file;
};

export let string: typeof tr | typeof en = new algoritm();
